'use client';

import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import reducer from './reducer';
import imageReducer from './imageReducer';
import initialContext from './InitialContext';
import initialImageContext from './InitialImageContext';

const localContextKey = 'decopacContext';
const localContextKeyImages = 'decopacContextImages';

/*
 * Return all keys in an Object
 */
function getDeepKeys(obj, keysArr = []) {
  Object.entries(obj).forEach(([key, value]) => {
    // TODO: array should compare schema and type
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      getDeepKeys(value, keysArr);
    } else {
      keysArr.push(key);
    }
  });
  return keysArr;
}

/*
 * Determine if two objects have the same keys
 */
function deepObjKeysEqual(obj1, obj2) {
  let keys1 = getDeepKeys(obj1 || {});
  let keys2 = getDeepKeys(obj2 || {});

  keys1.sort();
  keys2.sort();

  keys1 = keys1.join('');
  keys2 = keys2.join('');

  return keys1 === keys2;
}

let localContext;
let localImageContext;
if (typeof window !== 'undefined') {
  localContext = JSON.parse(localStorage.getItem(localContextKey));
  localImageContext = JSON.parse(localStorage.getItem(localContextKeyImages));

  if (localContext) {
    // Determine if context and localStorage have the same Obj keys
    const haveEqualKeys = deepObjKeysEqual(initialContext, localContext);
    const haveEqualImageKeys = deepObjKeysEqual(initialImageContext, localImageContext);

    // eslint-disable-next-line no-console
    console.log('haveEqualKeys:', haveEqualKeys, initialContext, localContext);
    // eslint-disable-next-line no-console
    console.log('haveEqualImageKeys:', haveEqualImageKeys, initialImageContext, localImageContext);

    // if the keys aren't equal then there has probably been an update
    // to the initialContext, so we need to reset localStorage
    if (!haveEqualKeys) {
      localStorage.removeItem(localContextKey);
      localContext = null;
    }
    if (!haveEqualImageKeys) {
      localStorage.removeItem(localContextKeyImages);
      localImageContext = null;
    }
  }
}

// reset local context
function resetLocalContext() {
  localStorage.removeItem(localContextKey);
  localContext = null;
  localStorage.removeItem(localContextKeyImages);
  localImageContext = null;
}

export const AppContext = createContext();

export function AppProvider({ children }) {
  const [context, setContext] = useReducer(reducer, localContext || initialContext);
  const [imageContext, setImageContext] = useReducer(
    imageReducer,
    localImageContext || initialImageContext,
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Remove random [object Object] from photocake layers
      // TODO: Improve if needed
      context?.cakeCustomize?.photocakeData?.objects?.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        if (item['[object Object]']) delete item['[object Object]'];
      });
      try {
        localStorage.setItem(localContextKey, JSON.stringify(context));
        localStorage.setItem(localContextKeyImages, JSON.stringify(imageContext));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error ', e);
        localStorage.removeItem(localContextKey);
        localStorage.removeItem(localContextKeyImages);
        localContext = null;
      }
    }
  }, [context, imageContext]);

  return (
    <AppContext.Provider
      value={useMemo(() => {
        return {
          context,
          imageContext,
          zip: context.zip,
          serviceAreaDialogOpen: context.serviceAreaDialogOpen,
          setContext,
          setImageContext,
          lat: context.lat,
          lon: context.lon,
          resetLocalContext,
        };
      }, [context, imageContext])}
    >
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
