import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const variants = {
  'heading-900': 'h1',
  'heading-800': 'h2',
  'heading-700': 'h3',
  'heading-600': 'h4',
  'heading-400': 'h5',
  'body-bold': 'strong',
  'body-medium': 'span',
  body: 'span',
  link: 'link',
  small: 'span',
  'small-medium': 'span',
  'small-bold': 'span',
  eyebrow: 'span',
  'eyebrow-medium': 'span',
  'eyebrow-uppercase': 'span',
  'text-link': 'span',
  'step-number': 'span',
};

const Typography = ({
  addClasses = '',
  ariaLabel,
  ariaLive,
  children,
  colorOverride,
  htmlTagOverride,
  id = null,
  role,
  style,
  variant,
  ...rest
}) => {
  if (!variant || !children) return null;

  const Element = htmlTagOverride || variants[variant];
  if (!Element) return null;

  const classes = classnames(
    'typography',
    {
      [addClasses]: addClasses,
      [`typography-${variant}`]: variant,
      [`color-${colorOverride}`]: colorOverride,
    },
  );

  return (
    <Element
      aria-label={ariaLabel}
      aria-live={ariaLive}
      className={classes}
      id={id}
      role={role}
      style={style}
      {...rest}
    >
      {children}
    </Element>
  );
};

Typography.propTypes = {
  addClasses: PropTypes.string,
  children: PropTypes.node.isRequired,
  colorOverride: PropTypes.node,
  htmlTagOverride: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'address',
    'div',
  ]),
  id: PropTypes.string,
  variant: PropTypes.oneOf([
    'heading-900',
    'heading-800',
    'heading-700',
    'heading-600',
    'heading-400',
    'body-bold',
    'body-medium',
    'body',
    'link',
    'small',
    'small-medium',
    'small-bold',
    'eyebrow',
    'eyebrow-medium',
    'eyebrow-uppercase',
    'text-link',
    'step-number',
  ]).isRequired,
  ariaLive: PropTypes.string, // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
  ariaLabel: PropTypes.string,
  role: PropTypes.string, // accessibility role
  style: PropTypes.object, // additional inline styles
};

export default Typography;
