import types from '../actions/types';
import initialImageContext from './InitialImageContext';

export default function Reducer(context, action) {
  switch (action.type) {
    case types.RESET_CAKE_CUSTOMIZE_IMAGES:
      return {
        ...context,
        cakeCustomize: {
          ...initialImageContext.cakeCustomize,
        },
      };
    case types.UPLOAD_CAKE_PHOTO:
      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          photocakeUploads: [...context.cakeCustomize.photocakeUploads, action.payload],
        },
      };
    case types.REMOVE_CAKE_PHOTO:
      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          photocakeUploads: context.cakeCustomize.photocakeUploads.filter((obj) => {
            return obj !== action.payload;
          }),
        },
      };
    case types.SET_PHOTOCAKE_IMG:
      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          photocakeImg: action.payload,
        },
      };
    case types.SET_STATIC_PREVIEW_IMG:
      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          staticPreviewImg: action.payload,
        },
      };
    case types.SET_PREVIEW_CAKE_IMG:
      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          previewCakeImg: action.payload,
        },
      };
    default:
      return context;
  }
}
