import { v4 as uuidv4 } from 'uuid';

export default {
  // TODO: Update LS schema to below - Noah
  // uuid: uuidv4(),
  // cake: {
  //   id: '',
  //   name: '',
  //   urlPath: '',
  //   customization: {
  //     step: 1, // 1, 2, 3, 4, 5, 6
  //     data: {
  //       size: 'QTR', // "8TH", "QTR", "HLF", "SIR"
  //       borderColor: {
  //         title: '',
  //         index: null,
  //       },
  //       filling: {
  //         title: '',
  //         index: null,
  //       },
  //       flavor: {
  //         title: '',
  //         index: null,
  //       },
  //       frosting: {
  //         title: '',
  //         index: null,
  //       },
  //       preview: {
  //         layers: { ...getInitialPreviewLayers() },
  //         image: '',
  //       },
  //       photocake: {
  //         image: '',
  //         live: '',
  //         renders: '',
  //         uploads: '',
  //         text: {
  //           text: '',
  //           size: null,
  //           rotation: 0,
  //           font: '',
  //           color: '',
  //           flex: 0,
  //         },
  //         data: {
  //           background: null,
  //           decoData: {
  //             build: null,
  //             cakeTopping: {
  //               height: null,
  //               name: '',
  //               shape: '',
  //               width: null,
  //             },
  //             currSize: {
  //               height: null,
  //               width: null,
  //             },
  //             objects: [],
  //           },
  //         },
  //       },
  //     },
  //   },
  // },
  cakeCustomize: {
    defaultScaleValue: null,
    savedRotateValue: null,
    savedScaleValue: null,
    previewLayers: [],
    photocakeLive: '',
    photocakeRenders: '',
    photocakeText: [{
      color: '',
      colorCode: '',
      flexSlide: 0,
      font: '',
      hasManualTransform: false,
      id: '',
      rotateSlide: 0,
      scaleSlide: 1,
      text: '',
    }],
    photocakeData: {
      background: null,
      decoData: {
        build: null,
        cakeTopping: {
          height: null,
          shape: '',
          width: null,
        },
        currSize: {
          height: null,
          width: null,
        },
      },
      objects: [],
    },
  },
  orderData: {
    background: {
      title: '',
      subtitle: '',
      value: '',
    },
    configuration: [],
    addOns: [],
    productId: null,
    productFormat: '',
    productType: '',
    productSku: null,
    productName: '',
    uuid: uuidv4(),
  },
  orderTotal: 0.00,
  lat: '',
  lon: '',
  serviceAreaDialogOpen: false,
  zip: '',
  promoBannerHidden: false,
  promoBannerDismissedOn: 0,
  promoBannerDisabled: true,
  isCustomizationModalOpen: false,
  kioskMode: false,
  isConfirmUpdateLocationModalOpen: false,
  newLocation: null, // only used for updating location via the confirmation modal
};
