/* eslint-disable react/destructuring-assignment */
import { v4 as uuidv4 } from 'uuid';
import types from '../actions/types';
import initialContext from './InitialContext';

export default function Reducer(context, action) {
  switch (action.type) {
    case types.SET_CONFIGURATION_SECTIONS:
      return {
        ...context,
        orderData: {
          ...context.orderData,
          configuration: action.payload,
        },
      };
    case types.UPDATE_CONFIGURATION:
      return {
        ...context,
        orderData: {
          ...context.orderData,
          configuration: context.orderData.configuration.map((item) => {
            return item.id === action.payload.id
              ? {
                ...item,
                selection: action.payload.selection,
                displayData: action.payload.displayData,
              }
              : item;
          }),
        },
      };
    case types.RESET_CAKE_CUSTOMIZE:
      return {
        ...context,
        cakeCustomize: {
          ...initialContext.cakeCustomize,
          photocakeText: [{
            ...initialContext.cakeCustomize.photocakeText[0],
          }],
        },
      };
    case types.RESET_ORDER_DATA:
      return {
        ...context,
        orderData: {
          ...initialContext.orderData,
          addOns: [], // For some reason initialContext is not reliable here
          productId: action.payload.productId,
          productFormat: action.payload.productFormat,
          productSku: action.payload.productSku,
          productName: action.payload.productName,
          uuid: uuidv4(), // Generate new UUID
        },
      };
    case types.CLEAR_ORDER_DATA:
      return {
        ...context,
        orderData: {
          ...initialContext.orderData,
          addOns: [], // For some reason initialContext is not reliable here
          uuid: uuidv4(), // Generate new UUID
        },
        orderTotal: initialContext.orderTotal,
      };
    case types.REFRESH_CART_ID:
      return {
        ...context,
        orderData: {
          ...context.orderData,
          uuid: uuidv4(), // Generate new UUID
        },
      };
    case types.UPDATE_BACKGROUND:
      return {
        ...context,
        orderData: {
          ...context.orderData,
          background: action.payload,
        },
      };
    case types.UPDATE_PRODUCT_TYPE:
      return {
        ...context,
        orderData: {
          ...context.orderData,
          productType: action.payload,
        },
      };
    case types.UPDATE_TOTAL:
      return {
        ...context,
        orderTotal: action.payload,
      };
    case types.UPDATE_ZIP_CODE:
      return {
        ...context,
        zip: action.payload,
      };
    case types.UPDATE_SHOW_SERVICE_AREA:
      return {
        ...context,
        serviceAreaDialogOpen: action.payload,
      };
    case types.UPDATE_GEO_LOCATION:
      return {
        ...context,
        lat: action.payload.lat,
        lon: action.payload.lon,
      };
    case types.SET_PREVIEW_IMAGE_LAYERS:
      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          previewLayers: action.payload,
        },
      };

    case types.SET_ROTATE_VALUE:
      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          savedRotateValue: action.payload,
        },
      };
    case types.SET_SCALE_VALUE:
      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          savedScaleValue: action.payload,
        },
      };
    case types.SET_DEFAULT_SCALE_VALUE:
      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          defaultScaleValue: action.payload,
        },
      };
    case types.SET_PHOTOCAKE_TEXT_DATA: {
      const newPhotocakeText = structuredClone(context.cakeCustomize.photocakeText);

      // If a "placeholder" item is in context, add ID to it
      if (newPhotocakeText?.length) {
        newPhotocakeText.forEach((item) => {
          if (item.id === '') {
            // eslint-disable-next-line no-param-reassign
            item.id = action.payload.textDataId;
          }
        });
      }

      // Find the element we need
      const updateElement = newPhotocakeText.find((textObj) => {
        return textObj.id === action.payload.textDataId;
      });

      // If we dont have the new item, we need to add it, else we update the current
      if (!updateElement) {
        newPhotocakeText.push({
          ...initialContext.cakeCustomize.photocakeText[0],
          id: action.payload.textDataId,
          [action.payload.textDataKey]: action.payload.textData,
        });
      } else {
        updateElement[action.payload.textDataKey] = action.payload.textData;
      }

      // Remove object from context if we set it to be deleted
      if (updateElement && action.payload.setToDelete) {
        newPhotocakeText.splice(
          newPhotocakeText.findIndex((v) => { return v.id === action.payload.textDataId; }), 1,
        );
      }

      // Always make sure we maintain at least one placeholder item in context at 0 capacity
      if (newPhotocakeText.length === 0) {
        newPhotocakeText.push({ ...initialContext.cakeCustomize.photocakeText[0] });
      }

      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          photocakeText: newPhotocakeText,
        },
      };
    }
    case types.SET_PHOTOCAKE_DATA:
      return {
        ...context,
        cakeCustomize: {
          ...context.cakeCustomize,
          photocakeData: action.payload,
        },
      };
    case types.PROMO_BANNER_HIDDEN:
      return {
        ...context,
        promoBannerHidden: action.payload,
      };
    case types.PROMO_BANNER_DISMISSED_ON:
      return {
        ...context,
        promoBannerDismissedOn: action.payload,
      };
    case types.PROMO_BANNER_DISABLED:
      return {
        ...context,
        promoBannerDisabled: action.payload,
      };
    case types.SET_CUSTOMIZATION_MODAL_OPEN:
      return {
        ...context,
        isCustomizationModalOpen: action.payload,
      };
    case types.UPDATE_ADD_ONS:
      return {
        ...context,
        orderData: {
          ...context.orderData,
          addOns: action.payload,
        },
      };
    case types.SET_KIOSK_MODE:
      return {
        ...context,
        kioskMode: true,
      };
    case types.KIOSK_RESET:
      return {
        ...initialContext,
        kioskMode: true,
      };
    case types.SET_CONFIRM_UPDATE_LOCATION_MODAL_OPEN:
      return {
        ...context,
        isConfirmUpdateLocationModalOpen: action.payload,
      };
    case types.SET_NEW_LOCATION:
      return {
        ...context,
        newLocation: action.payload,
      };
    default:
      return context;
  }
}
