import types from './types';

export const setConfigurationSections = (setContext, sections) => {
  setContext({
    type: types.SET_CONFIGURATION_SECTIONS,
    payload: sections,
  });
};

/**
 * Updates the orderData.configuration array by ID with selection data
 *
 * @param {function} setContext - Context setter
 * @param {string} id - Configuration section ID ref: src/utils/ConfiguratorOptions.js
 * @param {object} selection - Users selection formatted for order API
 * @param {object} displayData - Full selection option data for frontend display
 */
export const updateConfiguration = (setContext, id, selection, displayData) => {
  setContext({
    type: types.UPDATE_CONFIGURATION,
    payload: {
      id,
      selection,
      displayData,
    },
  });
};

export const updateBackground = (setContext, background) => {
  setContext({
    type: types.UPDATE_BACKGROUND,
    payload: background,
  });
};

export const updateProductType = (setContext, productType) => {
  setContext({
    type: types.UPDATE_PRODUCT_TYPE,
    payload: productType,
  });
};

export const updateTotal = (setContext, total) => {
  setContext({
    type: types.UPDATE_TOTAL,
    payload: total,
  });
};

// Upload Cake Photo
export const uploadCakePhoto = (setContext, photocakeUploads) => {
  setContext({
    type: types.UPLOAD_CAKE_PHOTO,
    payload: photocakeUploads,
  });
};

// Remove image from photocake uploads by url
export const removeCakePhoto = (setContext, imgSrc) => {
  setContext({
    type: types.REMOVE_CAKE_PHOTO,
    payload: imgSrc,
  });
};

// Reset cake customize options with new cake id
export const resetCakeCustomize = (setContext) => {
  setContext({
    type: types.RESET_CAKE_CUSTOMIZE,
  });
};

/**
 * Resets orderData with new product details
 * @param {function} setContext - Context setter
 * @param {number} productId - product.item
 * @param {string} productFormat - product.format
 * @param {number} productSku - product.sku
 * @param {number} productName - product.name
 */
export const resetOrderData = (
  setContext,
  productId,
  productFormat,
  productSku,
  productName,
) => {
  setContext({
    type: types.RESET_ORDER_DATA,
    payload: {
      productId,
      productFormat,
      productSku,
      productName,
    },
  });
};

// Reset order data on successful order
export const clearOrderData = (setContext) => {
  setContext({
    type: types.CLEAR_ORDER_DATA,
  });
};

// Refresh cart UUID
export const refreshCartId = (setContext) => {
  setContext({
    type: types.REFRESH_CART_ID,
  });
};

// Reset cake customize images
export const resetCakeCustomizeImages = (setContext) => {
  setContext({
    type: types.RESET_CAKE_CUSTOMIZE_IMAGES,
  });
};

// Set the preview image layers
export const setPreviewImageLayers = (setContext, layers) => {
  setContext({
    type: types.SET_PREVIEW_IMAGE_LAYERS,
    payload: layers,
  });
};

// Set the photo cake text data
export const setPhotocakeTextData = (
  setContext,
  textData,
  textDataKey,
  textDataId,
  setToDelete,
) => {
  setContext({
    type: types.SET_PHOTOCAKE_TEXT_DATA,
    payload: { textData, textDataKey, textDataId, setToDelete },
  });
};

// Set the value for uploaded photo rotation, from -180 to 180
export const setRotateValue = (setContext, uploadRotateValue) => {
  setContext({
    type: types.SET_ROTATE_VALUE,
    payload: uploadRotateValue,
  });
};

// Set the value for uploaded photo scaling
export const setScaleValue = (setContext, uploadScaleValue) => {
  setContext({
    type: types.SET_SCALE_VALUE,
    payload: uploadScaleValue,
  });
};

// Set the default value for uploaded photo scaling
export const setDefaultScaleValue = (setContext, defaultScaleValue) => {
  setContext({
    type: types.SET_DEFAULT_SCALE_VALUE,
    payload: defaultScaleValue,
  });
};

// Save the the current state of the photocake topping as a PNG
export const setPhotocakeImg = (setContext, img) => {
  setContext({
    type: types.SET_PHOTOCAKE_IMG,
    payload: img,
  });
};

// Save the cake preview img with most recent editor topping state
export const setCakePreviewImg = (setContext, img) => {
  setContext({
    type: types.SET_PREVIEW_CAKE_IMG,
    payload: img,
  });
};

// Save the cake preview img with most recent editor topping state
export const setStaticPreviewImg = (setContext, img) => {
  setContext({
    type: types.SET_STATIC_PREVIEW_IMG,
    payload: img,
  });
};

// Export the the current state of the photocake topping as JSON
export const setPhotocakeData = (setContext, data) => {
  setContext({
    type: types.SET_PHOTOCAKE_DATA,
    payload: data,
  });
};

// Set CustomizationModal open: boolean
export const setCustomizationModalOpen = (setContext, open) => {
  setContext({
    type: types.SET_CUSTOMIZATION_MODAL_OPEN,
    payload: open,
  });
};

// Updates the add-ons in the cart
export const updateAddOns = (setContext, addOns) => {
  setContext({
    type: types.UPDATE_ADD_ONS,
    payload: addOns,
  });
};

// Set the ConfirmResetLocation modal open
export const setConfirmUpdateLocationModalOpen = (setContext, open) => {
  setContext({
    type: types.SET_CONFIRM_UPDATE_LOCATION_MODAL_OPEN,
    payload: open,
  });
};

// Set new location (only used from the confirm update location modal when you have customizations)
export const setNewLocation = (setContext, newLocation) => {
  setContext({
    type: types.SET_NEW_LOCATION,
    payload: newLocation,
  });
};
