'use client';

import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Typography from '../Typography';

const Button = ({
  addClasses = '',
  ariaLabel,
  click = () => {},
  dataName = 'button',
  disabled = false,
  href,
  Icon = null,
  Icon2 = null,
  iconSide,
  id = null,
  label,
  overrides = null,
  prefetch = false,
  size = 'large',
  theme = 'light',
  trackingDetails,
  type = 'button',
  variant = 'primary',
  target = '_self',
}) => {
  const onClick = (e) => {
    if (click && e) {
      click(e);
    }
  };

  const classes = classnames('button', {
    [`button-icon-${iconSide}`]: iconSide,
    [`button-${size}`]: size,
    [`button-${theme}`]: theme,
    [`button-${variant}`]: variant,
    [addClasses]: addClasses,
  });

  // if the props match this criteria, then we need to render the raised primary button
  const raisedButton = variant === 'primary' && (size === 'large' || size === 'xlarge') && !disabled;

  // if there is an icon we need to determine if it goes on the left or right
  let trueLabel = label;
  if (iconSide && Icon) {
    trueLabel = iconSide === 'left' ? [<Icon key={iconSide} />, label] : [label, <Icon key={iconSide} />];
  }

  // Enables a second icon if needed
  if (Icon && Icon2) {
    trueLabel = [<Icon key="left" />, label, <Icon2 key="right" />];
  }

  // now we combine the above to make the children of the button
  const children = raisedButton ? (<span className={`button-${variant}-face`}>{trueLabel}</span>) : trueLabel;

  const dataContentName = trackingDetails?.contentName;
  const dataContentPiece = trackingDetails?.contentPiece;

  let childElement;

  switch (variant) {
    case 'text-link':
      childElement = (
        <Typography variant="text-link">{label}</Typography>
      );
      break;
    case 'small-medium':
      childElement = (
        <Typography variant="small-medium" addClasses="color-n1-300">{label}</Typography>
      );
      break;
    default:
      childElement = children;
  }

  if (overrides?.forceButton) {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        disabled={disabled}
        onClick={() => { overrides.handleClick({ url: href, name: dataName }); }}
        className={classes}
        aria-label={ariaLabel}
      >
        {childElement}
      </button>
    );
  }

  if (href) {
    return (
      <Link
        className={`btn-link ${classes}`}
        data-content-name={dataContentName}
        data-content-piece={dataContentPiece}
        href={href}
        id={id}
        onClick={onClick}
        prefetch={prefetch}
        target={target}
      >
        {childElement}
      </Link>
    );
  }

  return (
    <button
      className={classes}
      disabled={disabled}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type}
      data-content-name={dataContentName}
      data-content-piece={dataContentPiece}
      id={id}
      aria-label={ariaLabel}
    >
      {childElement}
    </button>
  );
};

Button.propTypes = {
  click: PropTypes.func,
  disabled: PropTypes.bool,
  Icon: PropTypes.func,
  Icon2: PropTypes.func,
  iconSide: PropTypes.oneOf([
    'left',
    'right',
  ]),
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  size: PropTypes.oneOf([
    'large',
    'small',
    'wide',
    'nav',
    'xlarge',
  ]),
  theme: PropTypes.oneOf([
    'light',
    'dark',
    'white',
  ]),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'dark',
    'tertiary',
    'icon-only',
    'icon-with-text',
    'pill',
    'text-link',
    'small-medium',
  ]),
  addClasses: PropTypes.string,
  type: PropTypes.oneOf([
    'button',
    'submit',
    'reset',
  ]),
  trackingDetails: PropTypes.object,
  id: PropTypes.string,
  prefetch: PropTypes.bool,
  href: PropTypes.string,
  ariaLabel: PropTypes.string,
  dataName: PropTypes.string,
  overrides: PropTypes.shape({
    forceButton: PropTypes.bool,
    handleClick: PropTypes.func,
  }),
};

export default Button;
