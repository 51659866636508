const ACTIONS = {
  SET_CONFIGURATION_SETTINGS: 'SET_CONFIGURATION_SETTINGS',
  UPDATE_CONFIGURATION: 'UPDATE_CONFIGURATION',
  RESET_CAKE_CUSTOMIZE: 'RESET_CAKE_CUSTOMIZE',
  RESET_ORDER_DATA: 'RESET_ORDER_DATA',
  REFRESH_CART_ID: 'REFRESH_CART_ID',
  CLEAR_ORDER_DATA: 'CLEAR_ORDER_DATA',
  RESET_CAKE_CUSTOMIZE_IMAGES: 'RESET_CAKE_CUSTOMIZE_IMAGES',
  UPDATE_IMAGE_UPLOAD: 'UPDATE_IMAGE_UPLOAD',
  UPDATE_PRODUCT_TYPE: 'UPDATE_PRODUCT_TYPE',
  UPDATE_BACKGROUND: 'UPDATE_BACKGROUND',
  UPDATE_TOTAL: 'UPDATE_TOTAL',
  UPLOAD_CAKE_PHOTO: 'UPLOAD_CAKE_PHOTO',
  REMOVE_CAKE_PHOTO: 'REMOVE_CAKE_PHOTO',
  SET_ROTATE_VALUE: 'SET_ROTATE_VALUE',
  SET_SCALE_VALUE: 'SET_SCALE_VALUE',
  SET_DEFAULT_SCALE_VALUE: 'SET_DEFAULT_SCALE_VALUE',
  UPDATE_ADD_ONS: 'UPDATE_ADD_ONS',

  UPDATE_ZIP_CODE: 'UPDATE_ZIP_CODE',
  UPDATE_GEO_LOCATION: 'UPDATE_GEO_LOCATION',
  UPDATE_SHOW_SERVICE_AREA: 'UPDATE_SHOW_SERVICE_AREA',

  SET_PREVIEW_IMAGE_LAYERS: 'SET_PREVIEW_IMAGE_LAYERS',

  SET_PHOTOCAKE_TEXT_DATA: 'SET_PHOTOCAKE_TEXT_DATA',
  SET_PHOTOCAKE_IMG: 'SET_PHOTOCAKE_IMG',
  SET_PHOTOCAKE_DATA: 'SET_PHOTOCAKE_DATA',
  SET_PREVIEW_CAKE_IMG: 'SET_PREVIEW_CAKE_IMG',
  SET_STATIC_PREVIEW_IMG: 'SET_STATIC_PREVIEW_IMG',

  PROMO_BANNER_HIDDEN: 'PROMO_BANNER_HIDDEN',
  PROMO_BANNER_DISMISSED_ON: 'PROMO_BANNER_DISMISSED_ON',
  PROMO_BANNER_DISABLED: 'PROMO_BANNER_DISABLED',

  SET_CUSTOMIZATION_MODAL_OPEN: 'SET_CUSTOMIZATION_MODAL_OPEN',

  SET_KIOSK_MODE: 'SET_KIOSK_MODE',
  KIOSK_RESET: 'KIOSK_RESET',

  SET_CONFIRM_UPDATE_LOCATION_MODAL_OPEN: 'SET_CONFIRM_UPDATE_LOCATION_MODAL_OPEN',
  SET_NEW_LOCATION: 'SET_NEW_LOCATION',
};

export default ACTIONS;
