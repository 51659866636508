const baseTemplates = {
  '/': 'home',
  '/checkout/': 'checkout',
  '/configurator/': 'configurator',
  '/confirmation/[orderid]/': 'order confirmation',
  '/location/[locationslug]/': 'store page',
  '/locations/': 'ciq store locator',
  '/order/[orderid]/': 'order status',
  '/product/[product]/': 'product',
  '/search/': 'search',
  '/[category]/': 'category',
  '/[category]/[subcategory]/': 'subcategory',
};

const wwwTemplates = {
  '/locations/': 'cakes.com store locator',
  '/location/[locationid]/': 'store page',
};

function getTemplate(pathname, wwwSubdomain = false) {
  const templateObj = wwwSubdomain ? wwwTemplates : baseTemplates;
  return templateObj[pathname] || '';
}

export default getTemplate;
