const urls = {
  addOns: '/add-ons/',
  checkout: '/checkout/',
  configurator: '/configurator/',
  confirmation: '/confirmation/',
  editor: '/customize/',
  home: '/',
  kioskUpload: '/kiosk/upload/',
  kioskUploadConfirmation: '/kiosk/upload/confirmation/',
  landing: '/landing/', // used as homepage for www.cakes.com
  location: '/location/',
  orderStatus: '/order/',
  product: '/product/',
  search: '/search/',
  storeLocator: '/locations/',
  www: '/www/', // used for pages shown at www.cakes.com
};

export default urls;
